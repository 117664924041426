import React from "react";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";

export default function LoadingScreen() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        textAlign: "center",
      }}
    >
      <Stack
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
          gap: 3,
        }}
      >
        <CircularProgress size={30} />
        <Typography variant="h5">Carregando...</Typography>
      </Stack>
    </Box>
  );
}
