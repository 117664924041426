import React from "react";
import { BrowserRouter, Route, Routes } from "react-router";

import FloatingActionButtonSize from "./components/FloatingButton";

import App from "./App";

import AdminDashboardPage from "./admin";
import AddClientPage from "./admin/pages/AddClientPage";
import EditClientPage from "./admin/pages/EditClientPage";

import ProtectedRoute from "./utils/ProtectedRoute";

import VoucherPage from "./pages/Voucher";
import AuthenticatePage from "./auth";

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="*"
          element={
            <>
              <FloatingActionButtonSize />
              <App />
            </>
          }
        />
        <Route path="authenticate" element={<AuthenticatePage />} />
        <Route path="admin-management" element={<ProtectedRoute />}>
          <Route index element={<AdminDashboardPage />} />
          <Route path="add" element={<AddClientPage />} />
          <Route path="edit">
            <Route index path=":id" element={<EditClientPage />} />
          </Route>
        </Route>
        <Route path="voucher">
          <Route index path=":id" element={<VoucherPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
