import React from "react";
import { useNavigate } from "react-router";

import { ref, set } from "firebase/database";

import { AppBar, Box, IconButton, Toolbar, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import ClientForm from "../forms/ClientForm";
import { useNotification } from "../../contexts/NotificationContext";

import { database } from "../../firebase/firebase";

export default function EditClientPage() {
  const navigate = useNavigate();

  const { showNotification } = useNotification();

  const goBack = () => navigate("/admin-management");

  const handleUpdate = (data: any, key: any) => {
    set(ref(database, `booking/${key}`), data)
      .then(() => {
        goBack();
        showNotification("success", "Reserva atualizada com sucesso!");
      })
      .catch((error) => {
        console.error("Error writing data: ", error);
        showNotification(
          "error",
          "Um erro aconteceu ao atualizar essa reserva."
        );
      });
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ backgroundColor: "#181818" }}>
        <Toolbar>
          <IconButton
            onClick={goBack}
            size="large"
            edge="start"
            aria-label="menu"
            color="inherit"
            sx={{ mr: 2 }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Editar
          </Typography>
        </Toolbar>
      </AppBar>
      <Box sx={{ p: 2 }}>
        <ClientForm onUpdate={handleUpdate} />
      </Box>
    </Box>
  );
}
