import React, { createContext, useContext, useState, ReactNode } from "react";
import Notification from "../components/Notification"; // Import your Notification component
import { AlertColor } from "@mui/material";

interface NotificationContextProps {
  showNotification: (type: AlertColor, message: string) => void;
}

const NotificationContext = createContext<NotificationContextProps | undefined>(
  undefined
);

export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error(
      "useNotification must be used within a NotificationProvider"
    );
  }
  return context;
};

export const NotificationProvider = ({ children }: { children: ReactNode }) => {
  const [open, setOpen] = useState(false);
  const [type, setType] = useState<AlertColor>("success");
  const [message, setMessage] = useState("");

  const showNotification = (type: AlertColor, message: string) => {
    setType(type);
    setMessage(message);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  return (
    <NotificationContext.Provider value={{ showNotification }}>
      {children}
      <Notification open={open} onClose={handleClose} type={type}>
        {message}
      </Notification>
    </NotificationContext.Provider>
  );
};
