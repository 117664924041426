import React from "react";
import { Alert, AlertColor, Snackbar } from "@mui/material";

interface Props {
  children: string;
  open: boolean;
  onClose: () => void;
  type: AlertColor;
}

export default function Notification({ children, open, onClose, type }: Props) {
  return (
    <div>
      <Snackbar open={open} onClose={onClose} autoHideDuration={3000}>
        <Alert
          onClose={onClose}
          severity={type}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {children}
        </Alert>
      </Snackbar>
    </div>
  );
}
