import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { ref, remove } from "firebase/database";
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  IconButton,
  Modal,
  Paper,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import {
  AirplaneTicket,
  Delete,
  Edit,
  IosShare,
  MoreVert,
  Person,
  SimCardDownload,
  WhatsApp,
} from "@mui/icons-material";

import dayjs from "dayjs";
import jsPDF from "jspdf";

import { useNotification } from "../../contexts/NotificationContext";

import { database } from "../../firebase/firebase";

import "dayjs/locale/pt-br";

import black_logo from "../../assets/images/black_logo.png";

dayjs.locale("pt-br");

interface Props {
  booking: any;
  done: boolean;
}

const Item = styled(Paper)(({ theme }) => ({
  padding: 16,
  marginBottom: 16,
  borderRadius: "12px",
}));

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 280,
  bgcolor: "background.paper",
  borderRadius: 3,
  boxShadow: 24,
  p: 3,
};

export default function BookingCard({ booking, done }: Props) {
  const [passengersName, setPassengersName] = useState<any>();
  const [anchorEl, setAnchorEl] = useState(null);
  const [currency, setCurrency] = useState("R$");
  const [multipliesCurrency, setMultipliesCurrency] = useState(1);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("Dinheiro");

  const { showNotification } = useNotification();
  const navigate = useNavigate();

  useEffect(() => {
    if (booking.passengersQty && booking.passengersQty >= 2) {
      const passengers = [];

      passengers.push(booking.name);

      for (let i = 2; i <= booking.passengersQty; i++) {
        passengers.push(booking[`passenger${i}`]);
      }

      setPassengersName(passengers.join(", ").toString());
    }
  }, [booking]);

  useEffect(() => {
    if (booking.currency) {
      switch (booking.currency) {
        case "dollar":
          setCurrency("$");
          setMultipliesCurrency(0.16);
          break;
        case "euro":
          setCurrency("€");
          setMultipliesCurrency(0.16);
          break;
        case "peso":
          setCurrency("₱");
          setMultipliesCurrency(166.21);
          break;
        case "real":
        default:
          setCurrency("R$");
          setMultipliesCurrency(1);
          break;
      }
    }
  }, [booking.currency]);

  useEffect(() => {
    switch (booking.paymentMethod) {
      case "pix":
        setPaymentMethod("PIX");
        break;
      case "card":
        setPaymentMethod("Cartão");
        break;
      case "cash":
      default:
        setPaymentMethod("Dinheiro");
        break;
    }
  }, [booking.paymentMethod]);

  const handleOpenModal = () => setDeleteModalOpen(true);
  const handleCloseModal = () => setDeleteModalOpen(false);

  const handleOpenPopover = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleShare = ({ url, text }: any) => {
    if (navigator.share) {
      navigator
        .share({
          title: text,
          url: url,
        })
        .then(() => console.log("Shared successfully"))
        .catch((error) => console.error("Error sharing:", error));
    } else {
      showNotification("warning", "Não foi possível compartilhar o voucher.");
    }
  };

  const handleDelete = () => {
    remove(ref(database, `booking/${booking.id}`))
      .then(() => {
        showNotification("success", "Reserva excluída com sucesso!");
        handleCloseModal();
      })
      .catch((error) => {
        console.error("Error writing data: ", error);
        showNotification("error", "Um erro aconteceu ao excluir essa reserva.");
      });
  };

  const handleDownloadPDF = () => {
    const doc = new jsPDF();

    const labelMapping: any = {
      name: "Nome",
      phone: "Telefone",
      flight: "Voo",
      date: "Data e hora",
      passengersQty: "Quantidade de Passageiros",
      vehicle: "Veículo",
      origin: "Origem",
      destination: "Destino",
      value: "Valor",
      paidValue: "Valor pago",
      currency: "Moeda",
      paymentMethod: "Forma de pagamento",
      observation: "Observações",
      passengers: "Passageiros",
    };

    doc.setFontSize(18);

    doc.text(
      `Reserva - ${booking.name} - ${dayjs(booking.date).format(
        "DD/MM/YYYY HH:mm"
      )}`,
      20,
      20
    );

    let yPosition = 30;
    let passengers = [];

    for (let i = 2; i <= 6; i++) {
      const passengerKey = `passenger${i}`;

      if (booking[passengerKey]) {
        passengers.push(booking[passengerKey]);

        delete booking[passengerKey];
      }
    }

    if (passengers.length > 0) {
      passengers.unshift(booking.name);

      booking.passengers = passengers.join(", ");
    }

    doc.setFontSize(12);

    Object.keys(booking).forEach((key) => {
      const value = booking[key];
      const label = labelMapping[key] || key;

      if (!value) {
        return;
      }

      let displayValue = value;

      if (key === "currency") {
        const currencyMap: any = {
          real: "Reais",
          dollar: "Dólar",
          euro: "Euro",
          pesos: "Pesos",
        };

        displayValue = currencyMap[value] || value;
      }

      if (key === "vehicle") {
        const vehicleMap: any = {
          1: "Chevrolet Onix",
          2: "Chevrolet Spin",
        };

        displayValue = vehicleMap[value] || value;
      }

      if (key === "date") {
        displayValue = dayjs(booking.date).format("DD/MM/YYYY - HH:mm");
      }

      if (key === "paymentMethod") {
        const paymentMethodMap: any = {
          card: "Cartão",
          cash: "Dinheiro",
          pix: "PIX",
        };

        displayValue = paymentMethodMap[value] || value;
      }

      doc.setFont("helvetica", "normal");
      doc.text(`${label}:`, 20, yPosition);

      yPosition += 6;

      doc.setFont("helvetica", "bold");
      doc.text(`${displayValue}`, 20, yPosition);

      yPosition += 10;
    });

    doc.addImage(black_logo, "PNG", 20, yPosition + 10, 180, 40);

    doc.save(
      `reserva-${booking.name}-${dayjs(booking.date).format("DD/MM/YYYY")}`
    );
    showNotification("info", "Baixando PDF...");
  };

  const itemStyle = done
    ? {
        backgroundColor: "rgba(0, 255, 0, 0.1)", // Light green for done
        border: "2px solid green",
      }
    : {};

  const convertedValue = (booking.value / multipliesCurrency).toFixed(0);

  return (
    <Box>
      <Item elevation={3} sx={itemStyle}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Person sx={{ mr: 1 }} />
            <Typography sx={{ fontWeight: "bold" }} variant="body1">
              {booking.name}
            </Typography>
          </Stack>
          <IconButton onClick={handleOpenPopover}>
            <MoreVert />
          </IconButton>
          <Popover open={open} anchorEl={anchorEl} onClose={handleClosePopover}>
            <Box
              sx={{
                p: 1,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Button
                sx={{
                  textTransform: "none",
                  justifyContent: "left",
                  color: "#000000",
                }}
                fullWidth
                target="_blank"
                href={`https://www.transferonfloripa.com.br/voucher/${booking.id}`}
              >
                Abrir voucher
              </Button>
              <Divider sx={{ m: 0.5 }} />
              <Button
                sx={{ textTransform: "none", justifyContent: "left" }}
                color="secondary"
                startIcon={<SimCardDownload />}
                fullWidth
                onClick={handleDownloadPDF}
              >
                Baixar PDF
              </Button>
              <Button
                sx={{ textTransform: "none", justifyContent: "left" }}
                color="primary"
                startIcon={<Edit />}
                fullWidth
                onClick={() => navigate(`edit/${booking.id}`)}
              >
                Editar
              </Button>
              <Button
                sx={{ textTransform: "none", justifyContent: "left" }}
                color="error"
                startIcon={<Delete />}
                fullWidth
                onClick={handleOpenModal}
              >
                Excluir
              </Button>
            </Box>
          </Popover>
        </Box>
        <Divider sx={{ mt: 1, mb: 1 }} />
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Typography variant="h5">
            {currency} {Number(booking.value)}
          </Typography>
          {booking.currency !== "real" && (
            <Typography variant="body1" sx={{ opacity: 0.5, paddingLeft: 1 }}>
              (R$ ~{convertedValue})
            </Typography>
          )}
        </Stack>
        {booking.paidValue > 0 && (
          <Typography variant="body1">
            Valor pago:{" "}
            <span style={{ fontWeight: "bold" }}>
              {currency} {Number(booking.paidValue)}
            </span>
          </Typography>
        )}
        <Typography variant="body1">
          Meio de pagamento:{" "}
          <span style={{ fontWeight: "bold" }}>{paymentMethod}</span>
        </Typography>
        {booking.phone && (
          <Typography variant="body1">
            Telefone:{" "}
            <span style={{ fontWeight: "bold" }}>{booking.phone}</span>
          </Typography>
        )}
        {booking.vehicle && (
          <Typography variant="body1">
            Veículo:{" "}
            <span style={{ fontWeight: "bold" }}>
              {booking.vehicle === 1 ? "Chevrolet Onix" : "Chevrolet Spin"}
            </span>
          </Typography>
        )}
        <Typography variant="body1">
          Qtd. Passageiros:{" "}
          <span style={{ fontWeight: "bold" }}>{booking.passengersQty}</span>
        </Typography>
        {passengersName && (
          <Typography variant="body1">
            Passageiros:{" "}
            <span style={{ fontWeight: "bold" }}>{passengersName}</span>
          </Typography>
        )}
        <Typography variant="body1">
          Origem: <span style={{ fontWeight: "bold" }}>{booking.origin}</span>
        </Typography>
        <Typography variant="body1">
          Destino:{" "}
          <span style={{ fontWeight: "bold" }}>{booking.destination}</span>
        </Typography>
        {booking.flight && (
          <Typography variant="body1">
            Voo: <span style={{ fontWeight: "bold" }}>{booking.flight}</span>
          </Typography>
        )}
        <Typography variant="body1">
          Horário:{" "}
          <span style={{ fontWeight: "bold" }}>
            {dayjs(booking.date).format("HH:mm")}
          </span>
        </Typography>
        {booking.observation && (
          <Typography variant="body1">
            Observações:{" "}
            <span style={{ fontWeight: "bold" }}>{booking.observation}</span>
          </Typography>
        )}
        <Divider sx={{ mt: 1, mb: 1 }} />
        {booking.flight && (
          <Button
            href={`https://www.flightradar24.com/${booking.flight}`}
            target="_blank"
            fullWidth
            variant="contained"
            color="primary"
            startIcon={<AirplaneTicket />}
            sx={{ mb: 1 }}
          >
            Acompanhar voo
          </Button>
        )}
        {booking.phone && (
          <Button
            href={`https://wa.me/${booking.phone}`}
            target="_blank"
            fullWidth
            variant="contained"
            startIcon={<WhatsApp />}
            sx={{ mb: 1, backgroundColor: "#25d366 !important" }}
          >
            WhatsApp
          </Button>
        )}
        <Button
          startIcon={<IosShare />}
          fullWidth
          variant="outlined"
          onClick={() =>
            handleShare({
              text: "Acompanhe seu voucher!",
              url: `https://www.transferonfloripa.com.br/voucher/${booking.id}`,
            })
          }
        >
          Compartilhar voucher
        </Button>
      </Item>
      <Modal open={deleteModalOpen} onClose={handleCloseModal}>
        <Box sx={modalStyle}>
          <Typography variant="h6" sx={{ fontWeight: "bold", pb: 1 }}>
            Excluir
          </Typography>
          <Divider />
          <Typography variant="body1" sx={{ py: 1 }}>
            Você tem certeza que deseja excluir essa reserva?
          </Typography>
          <ButtonGroup fullWidth>
            <Button startIcon={<Delete />} color="error" onClick={handleDelete}>
              Excluir
            </Button>
            <Button
              color="primary"
              onClick={handleCloseModal}
              variant="contained"
            >
              Cancelar
            </Button>
          </ButtonGroup>
        </Box>
      </Modal>
    </Box>
  );
}
