import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router";

import {
  Box,
  Button,
  ButtonProps,
  IconButton,
  Paper,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

import { get, ref } from "firebase/database";

import { database } from "../firebase/firebase";

import black_logo from "../assets/images/black_logo.png";
import dayjs from "dayjs";
import {
  EmailOutlined,
  Instagram,
  PhoneOutlined,
  TaskAlt,
} from "@mui/icons-material";

const WhatsappButton = styled(Button)<ButtonProps>(() => ({
  backgroundColor: "#25d366 !important",
}));

const InfoRow = ({ label, value }: any) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      padding: "4px 0",
    }}
  >
    <Typography variant="h6" component="span">
      {label}
    </Typography>
    <Box
      sx={{
        flex: 1,
        height: "1px",
        background:
          "repeating-linear-gradient(to right, #ddd, #ddd 2px, transparent 2px, transparent 4px)",
        margin: "0 8px",
      }}
    />
    <Typography variant="h6" component="span" sx={{ fontWeight: "bold" }}>
      {value}
    </Typography>
  </Box>
);

export default function Voucher() {
  const [booking, setBooking] = useState<any>();
  const [passengersName, setPassengersName] = useState("");
  const [currency, setCurrency] = useState("R$");

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    get(ref(database, `booking/${id}`)).then((snapshot) => {
      if (snapshot.exists()) {
        setBooking(snapshot.val());
      } else {
        return navigate("/");
      }
    });
  }, [id, navigate]);

  useEffect(() => {
    if (booking && booking.currency) {
      switch (booking.currency) {
        case "dollar":
          setCurrency("$");
          break;
        case "euro":
          setCurrency("€");
          break;
        case "peso":
          setCurrency("₱");
          break;
        case "real":
        default:
          setCurrency("R$");
          break;
      }
    }
  }, [booking]);

  useEffect(() => {
    if (booking && booking.passengersQty >= 2) {
      const passengers = [];

      passengers.push(booking.name);

      for (let i = 2; i <= booking.passengersQty; i++) {
        passengers.push(`${booking[`passenger${i}`]}`);
      }

      setPassengersName(passengers.join(", "));
    }
  }, [booking]);

  return (
    booking && (
      <Box sx={{ p: 3, backgroundColor: "#262626", minHeight: "100%" }}>
        <Stack spacing={3}>
          <Paper
            elevation={3}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              p: 3,
              textAlign: "center",
              borderRadius: 2,
            }}
          >
            <Stack spacing={2}>
              <span style={{ display: "flex", justifyContent: "center" }}>
                <Link to="/">
                  <img style={{ width: "224px" }} src={black_logo} alt="logo" />
                </Link>
              </span>
              <Typography variant="h5">{booking.name}</Typography>
              <Typography variant="h6">
                Obrigado por nos escolher para sua viagem! Sua reserva para{" "}
                <span style={{ fontWeight: "bold" }}>
                  {dayjs(booking.date).format("DD/MM/YYYY, HH:mm")}
                </span>{" "}
                foi confirmada!
              </Typography>
              <Box>
                <InfoRow
                  label="Valor:"
                  value={`${currency} ${Number(booking.value)}`}
                />
                {booking.paidValue > 0 && (
                  <InfoRow
                    label="Valor pago:"
                    value={`${currency} ${Number(booking.paidValue)}`}
                  />
                )}
                {passengersName && (
                  <InfoRow label="Passageiros:" value={passengersName} />
                )}
                <InfoRow label="Origem:" value={booking.origin} />
                <InfoRow label="Destino:" value={booking.destination} />
                {booking.flight && (
                  <InfoRow label="Voo:" value={booking.flight} />
                )}
                <InfoRow
                  label="Data:"
                  value={dayjs(booking.date).format("DD/MM/YYYY")}
                />
                <InfoRow
                  label="Horário:"
                  value={dayjs(booking.date).format("HH:mm")}
                />
              </Box>
              <Typography variant="h6">
                Caso tenha alguma dúvida, entre em contato pelo WhatsApp, e não
                se esqueça de conferir as instruções de embarque quando estiver
                disponível!
              </Typography>
              <WhatsappButton
                startIcon={<WhatsAppIcon />}
                variant="contained"
                href="https://wa.me/5548996523066"
              >
                WhatsApp
              </WhatsappButton>
              <Button disabled startIcon={<TaskAlt />} variant="contained">
                Instruções de embarque
              </Button>
            </Stack>
          </Paper>
          <Stack>
            <Link to="/" style={{ textAlign: "center", color: "gray" }}>
              <Typography>Transfer on Floripa</Typography>
            </Link>
            <Stack
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <IconButton
                href="https://wa.me/5548996523066"
                sx={{ color: "gray" }}
              >
                <WhatsAppIcon />
              </IconButton>
              <IconButton
                href="https://www.instagram.com/transfer.onfloripa/"
                sx={{ color: "gray" }}
              >
                <Instagram />
              </IconButton>
              <IconButton
                href="mailto:transfer.onfloripa@gmail.com"
                sx={{ color: "gray" }}
              >
                <EmailOutlined />
              </IconButton>
              <IconButton href="tel://5548996523066" sx={{ color: "gray" }}>
                <PhoneOutlined />
              </IconButton>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    )
  );
}
