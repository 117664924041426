import React from "react";
import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Logout } from "@mui/icons-material";

import { useAuth } from "../../contexts/AuthContext";
import { useNotification } from "../../contexts/NotificationContext";
import { auth } from "../../firebase/firebase";

const Item = styled(Paper)(({ theme }) => ({
  padding: 16,
  marginBottom: 16,
  borderRadius: "12px",
}));

export default function SettingsScreen() {
  const { user } = useAuth();

  const { showNotification } = useNotification();

  const handleSignOut = () => {
    auth.signOut().then(() => {
      return showNotification("info", "Você se desconectou de sua conta.");
    });
  };

  return (
    <Box>
      <Typography
        variant="h6"
        color="primary"
        sx={{
          fontWeight: 700, // Make the text bold for emphasis
          textTransform: "uppercase", // Makes the date stand out
          letterSpacing: "0.1em", // Adds spacing between letters for clarity
          lineHeight: 1.5, // Improves readability
          mb: 1,
        }}
      >
        Conta
      </Typography>
      <Item elevation={3}>
        <Stack spacing={1}>
          <Typography variant="body1">Você está logado como</Typography>
          <Typography sx={{ fontWeight: "bold" }} variant="h6">
            {user.email}
          </Typography>
          <Button
            variant="contained"
            color="error"
            fullWidth
            startIcon={<Logout />}
            onClick={handleSignOut}
          >
            Sair
          </Button>
        </Stack>
      </Item>
    </Box>
  );
}
