import React from "react";
import { Navigate, Outlet } from "react-router";

import { useAuth } from "../contexts/AuthContext";

import LoadingScreen from "./LoadingScreen";

const ProtectedRoute = () => {
  const { user, loading } = useAuth();

  if (loading) {
    return <LoadingScreen />;
  }

  return user ? <Outlet /> : <Navigate to="/authenticate" />;
};

export default ProtectedRoute;
