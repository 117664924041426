import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAHeDKm0ptWa0iOEqerfZlPBA_c-NifaXA",
  authDomain: "transferonfloripa-8efc0.firebaseapp.com",
  projectId: "transferonfloripa-8efc0",
  storageBucket: "transferonfloripa-8efc0.firebasestorage.app",
  messagingSenderId: "868198092904",
  appId: "1:868198092904:web:b81da498af8bce90f76bdd",
  measurementId: "G-9CE4R8QXKC",
  databaseURL: "https://transferonfloripa-8efc0-default-rtdb.firebaseio.com",
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const auth = getAuth(app);

export { app, database, auth };
