import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import dayjs from "dayjs";

import {
  endAt,
  get,
  orderByChild,
  query,
  ref,
  startAt,
} from "firebase/database";

import { Box, Stack, Typography } from "@mui/material";

import ButtonAppBar from "./components/AppBar";
import BookingCard from "./components/BookingCard";
import PanelNavigation from "./components/PanelNavigation";

import { database } from "../firebase/firebase";
import { useNotification } from "../contexts/NotificationContext";

import SettingsScreen from "./screens/SettingsScreen";
import LoadingScreen from "../utils/LoadingScreen";

dayjs.locale("pt-br");

export default function AdminDashboardPage() {
  const [bottomNavigation, setBottomNavigation] = useState(2);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any[]>();
  const [dataLength, setDataLength] = useState(0);

  const navigate = useNavigate();
  const { showNotification } = useNotification();

  useEffect(() => {
    let firebaseQuery;

    if (bottomNavigation === 1) {
      firebaseQuery = query(
        ref(database, "booking"),
        orderByChild("date"),
        endAt(dayjs().valueOf())
      );
    } else if (bottomNavigation === 2) {
      firebaseQuery = query(
        ref(database, "booking"),
        orderByChild("date"),
        startAt(dayjs().valueOf())
      );
    } else {
      return;
    }

    get(firebaseQuery).then((snapshot) => {
      if (snapshot.exists()) {
        setLoading(false);

        const booking = snapshot.val();

        const bookingArray = Object.entries(booking).map(
          ([id, value]: any) => ({
            id,
            ...value,
          })
        );

        setDataLength(bookingArray.length);

        const groupedBookings = bookingArray.reduce((acc, booking) => {
          const date = dayjs(booking.date).format("YYYY-MM-DD");

          if (!acc[date]) {
            acc[date] = [];
          }

          acc[date].push(booking);

          acc[date].sort(
            (a: any, b: any) =>
              new Date(a.date).getTime() - new Date(b.date).getTime()
          );

          return acc;
        }, {});

        const sortedGroupedBookings: any = Object.fromEntries(
          Object.entries(groupedBookings).sort(([dateA], [dateB]) =>
            bottomNavigation === 1
              ? new Date(dateB).getTime() - new Date(dateA).getTime()
              : new Date(dateA).getTime() - new Date(dateB).getTime()
          )
        );

        setData(sortedGroupedBookings);
      }
    });
  }, [bottomNavigation, showNotification]);

  const handleOpen = () => {
    navigate("add");
  };

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <Box sx={{ pb: 7, minHeight: "100%" }}>
      <ButtonAppBar handleAdd={handleOpen} />
      {!loading && bottomNavigation === 0 && (
        <Box sx={{ p: 2 }}>
          <SettingsScreen />
        </Box>
      )}
      {!loading && bottomNavigation !== 0 && (
        <Box sx={{ p: 2 }}>
          {data &&
            Object.entries(data).map(([date, bookings]: [string, any[]]) => (
              <Box key={date} sx={{ mb: 4 }}>
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    mb: 1,
                  }}
                >
                  <Typography
                    variant="h6"
                    color="primary"
                    sx={{
                      fontWeight: 700, // Make the text bold for emphasis
                      textTransform: "uppercase", // Makes the date stand out
                      letterSpacing: "0.1em", // Adds spacing between letters for clarity
                      lineHeight: 1.5, // Improves readability
                      textAlign: "center", // Centers the text
                    }}
                  >
                    {dayjs(date).format("DD MMMM YYYY")}
                  </Typography>
                  {bookings.length >= 2 && (
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: 700, // Make the text bold for emphasis
                        textTransform: "uppercase", // Makes the date stand out
                        letterSpacing: "0.1em", // Adds spacing between letters for clarity
                        lineHeight: 1.5, // Improves readability
                        textAlign: "center", // Centers the text
                      }}
                    >
                      {bookings.length}
                    </Typography>
                  )}
                </Stack>
                {bookings.map((booking) => (
                  <BookingCard
                    done={bottomNavigation === 1}
                    key={booking.id}
                    booking={booking}
                  />
                ))}
              </Box>
            ))}
        </Box>
      )}
      {data && (
        <PanelNavigation
          value={bottomNavigation}
          dataLength={dataLength}
          onChange={setBottomNavigation}
        />
      )}
    </Box>
  );
}
