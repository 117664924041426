import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";

import dayjs from "dayjs";
import { get, ref } from "firebase/database";

import FormControl from "@mui/material/FormControl";
import {
  Button,
  Divider,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
} from "@mui/material";

import {
  AccountCircle,
  CreditCard,
  DirectionsCar,
  Money,
  Person,
  Phone,
} from "@mui/icons-material";
import GroupsIcon from "@mui/icons-material/Groups";
import AirplaneTicketIcon from "@mui/icons-material/AirplaneTicket";
import PersonPinCircleIcon from "@mui/icons-material/PersonPinCircle";
import PinDropIcon from "@mui/icons-material/PinDrop";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";

import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";

import { useNotification } from "../../contexts/NotificationContext";

import "dayjs/locale/pt-br";

import { database } from "../../firebase/firebase";

interface Props {
  onAdd?: (data: object) => void;
  onUpdate?: (data: object, key: any) => void;
}

export default function AddClientForm({ onAdd, onUpdate }: Props) {
  const [data, setData] = useState<any>({
    name: "",
    passengersQty: 1,
    vehicle: 1,
    flight: "",
    date: dayjs(),
    origin: "",
    destination: "",
    currency: "real",
    paymentMethod: "cash",
    observation: "",
  });
  const [key, setKey] = useState<any>();

  const { id } = useParams();
  const navigate = useNavigate();
  const { showNotification } = useNotification();

  useEffect(() => {
    if (id) {
      get(ref(database, `booking/${id}`)).then((snapshot) => {
        if (snapshot.exists()) {
          const booking = snapshot.val();

          setKey(id);

          const receivedData: any = {
            name: booking.name || "",
            phone: booking.phone || 0,
            vehicle: booking.vehicle || 1,
            passengersQty: booking.passengersQty || 1,
            flight: booking.flight || "",
            date: booking.date ? dayjs(booking.date) : dayjs(),
            origin: booking.origin || "",
            destination: booking.destination || "",
            value: booking.value || 0,
            paidValue: booking.paidValue || 0,
            currency: booking.currency || "real",
            paymentMethod: booking.paymentMethod || "cash",
            observation: booking.observation || "",
          };

          if (booking.passengersQty >= 2) {
            for (let i = 2; i <= booking.passengersQty; i++) {
              receivedData[`passenger${i}`] = booking[`passenger${i}`];
            }
          }

          setData(receivedData);
        } else {
          return navigate("/admin-management");
        }
      });
    }
  }, [id, navigate]);

  const handleChange = (key: string, value: string | number) => {
    setData((prev: any) => ({ ...prev, [key]: value }));
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (
      !data.name ||
      !data.origin ||
      !data.destination ||
      !data.value ||
      !data.phone
    ) {
      return showNotification(
        "warning",
        "Por favor, preencha todos os campos obrigatórios."
      );
    }

    if (data.passengersQty >= 2) {
      const missingPassengers = [];

      for (let i = 2; i <= data.passengersQty; i++) {
        const passengerKey = `passenger${i}`;

        if (!data[passengerKey] || !data[passengerKey].trim()) {
          missingPassengers.push(`passageiro ${i}`);
        }
      }

      if (missingPassengers.length > 0) {
        return showNotification(
          "warning",
          `Por favor, preencha o nome dos seguintes passageiros: ${missingPassengers.join(
            ", "
          )}`
        );
      }
    }

    if (key && onUpdate) {
      data.date = dayjs(data.date).valueOf();

      return onUpdate(data, key);
    } else if (onAdd) {
      return onAdd(data);
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      noValidate
      autoCapitalize="words"
      autoComplete="no"
    >
      <FormControl sx={{ width: "100%" }}>
        <Stack spacing={2}>
          <Typography variant="body1" component="h3">
            Reserva
          </Typography>
          <FormControl>
            <InputLabel style={{ color: "#181818" }}>Nome *</InputLabel>
            <OutlinedInput
              error={data.name === ""}
              startAdornment={
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              }
              label="Nome *"
              onChange={(e) => handleChange("name", e.target.value)}
              placeholder="Nome da reserva"
              value={data.name}
            />
            {!data.name && (
              <FormHelperText sx={{ color: "red" }}>
                Este campo é obrigatório.
              </FormHelperText>
            )}
          </FormControl>
          <FormControl>
            <InputLabel style={{ color: "#181818" }}>
              Número de telefone *
            </InputLabel>
            <OutlinedInput
              error={!data.phone}
              startAdornment={
                <InputAdornment position="start">
                  <Phone />
                </InputAdornment>
              }
              label="Número de telefone *"
              onChange={(e) => handleChange("phone", e.target.value)}
              placeholder="Telefone"
              value={data.phone}
            />
            {!data.phone && (
              <FormHelperText sx={{ color: "red" }}>
                Este campo é obrigatório.
              </FormHelperText>
            )}
          </FormControl>
          <FormControl>
            <InputLabel id="vehicle" style={{ color: "#181818" }}>
              Veículo *
            </InputLabel>
            <Select
              defaultValue={"1"}
              onChange={(e) => handleChange("vehicle", e.target.value)}
              labelId="vehicle"
              value={data.vehicle}
              startAdornment={
                <InputAdornment position="start">
                  <DirectionsCar />
                </InputAdornment>
              }
              label="Veículo *"
              placeholder="Veículo"
            >
              <MenuItem value={1}>Chevrolet Onix</MenuItem>
              <MenuItem value={2}>Chevrolet Spin</MenuItem>
            </Select>
          </FormControl>
          <Typography variant="body1" component="h3">
            Passageiros
          </Typography>
          <FormControl>
            <InputLabel id="passengers" style={{ color: "#181818" }}>
              Qtd. Passageiros *
            </InputLabel>
            <Select
              defaultValue={"1"}
              onChange={(e) => handleChange("passengersQty", e.target.value)}
              labelId="passengers"
              value={data.passengersQty}
              startAdornment={
                <InputAdornment position="start">
                  <GroupsIcon />
                </InputAdornment>
              }
              label="Qtd. Passageiros *"
              placeholder="Quantidade de passageiros"
            >
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={4}>4</MenuItem>
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={6}>6</MenuItem>
            </Select>
          </FormControl>
          {data.passengersQty >= 2 &&
            Array.from({ length: data.passengersQty - 1 }).map((_, index) => {
              return (
                <FormControl key={index}>
                  <InputLabel style={{ color: "#181818" }}>
                    Passageiro {index + 2} *
                  </InputLabel>
                  <OutlinedInput
                    value={data[`passenger${index + 2}`]}
                    error={!data[`passenger${index + 2}`]}
                    onChange={(e) =>
                      handleChange(`passenger${index + 2}`, e.target.value)
                    }
                    startAdornment={
                      <InputAdornment position="start">
                        <Person />
                      </InputAdornment>
                    }
                    fullWidth
                    label={`Passageiro ${index + 2} *`}
                    placeholder="Nome do passageiro"
                  />
                  {!data[`passenger${index + 2}`] && (
                    <FormHelperText sx={{ color: "red" }}>
                      Este campo é obrigatório.
                    </FormHelperText>
                  )}
                </FormControl>
              );
            })}
          <Typography variant="body1" component="h3">
            Acompanhamento do voo
          </Typography>
          <FormControl>
            <InputLabel style={{ color: "#181818" }}>Voo</InputLabel>
            <OutlinedInput
              onChange={(e) => handleChange("flight", e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <AirplaneTicketIcon />
                </InputAdornment>
              }
              fullWidth
              label="Voo"
              placeholder="Número do voo (opcional)"
              value={data.flight}
            />
          </FormControl>
          <FormControl>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="pt-br"
            >
              <DemoContainer components={["DatePicker"]}>
                <DateTimePicker
                  onChange={(value) =>
                    handleChange("date", dayjs(value).valueOf())
                  }
                  defaultValue={dayjs()}
                  value={dayjs(data.date)}
                  label="Data e hora *"
                />
              </DemoContainer>
            </LocalizationProvider>
          </FormControl>
          <Typography variant="body1" component="h3">
            Origem e destino
          </Typography>
          <FormControl>
            <InputLabel style={{ color: "#181818" }}>Origem *</InputLabel>
            <OutlinedInput
              value={data.origin}
              error={data.origin === ""}
              startAdornment={
                <InputAdornment position="start">
                  <PersonPinCircleIcon />
                </InputAdornment>
              }
              fullWidth
              onChange={(e) => handleChange("origin", e.target.value)}
              label="Origem *"
              placeholder="Origem"
            />
            {!data.origin && (
              <FormHelperText sx={{ color: "red" }}>
                Este campo é obrigatório.
              </FormHelperText>
            )}
          </FormControl>
          <FormControl>
            <InputLabel style={{ color: "#181818" }}>Destino *</InputLabel>
            <OutlinedInput
              value={data.destination}
              error={data.destination === ""}
              startAdornment={
                <InputAdornment position="start">
                  <PinDropIcon />
                </InputAdornment>
              }
              fullWidth
              onChange={(e) => handleChange("destination", e.target.value)}
              label="Destino *"
              placeholder="Destino"
            />
            {!data.destination && (
              <FormHelperText sx={{ color: "red" }}>
                Este campo é obrigatório.
              </FormHelperText>
            )}
          </FormControl>
          <Typography variant="body1" component="h3">
            Pagamento
          </Typography>
          <FormControl>
            <InputLabel style={{ color: "#181818" }}>Valor *</InputLabel>
            <OutlinedInput
              error={!data.value}
              onChange={(e) => handleChange("value", e.target.value)}
              type="number"
              fullWidth
              label="Valor *"
              placeholder="Preço da viagem"
              value={data.value}
              startAdornment={
                <InputAdornment position="start">
                  <Money />
                </InputAdornment>
              }
            />
            {!data.value && (
              <FormHelperText sx={{ color: "red" }}>
                Este campo é obrigatório.
              </FormHelperText>
            )}
          </FormControl>
          <FormControl>
            <InputLabel style={{ color: "#181818" }}>Valor pago</InputLabel>
            <OutlinedInput
              onChange={(e) => handleChange("paidValue", e.target.value)}
              type="number"
              fullWidth
              label="Valor pago"
              placeholder="Valor pago (opcional)"
              value={data.paidValue}
              startAdornment={
                <InputAdornment position="start">
                  <Money />
                </InputAdornment>
              }
            />
          </FormControl>
          <FormControl>
            <InputLabel style={{ color: "#181818" }}>Moeda</InputLabel>
            <Select
              startAdornment={
                <InputAdornment position="start">
                  <LocalAtmIcon />
                </InputAdornment>
              }
              value={data.currency}
              fullWidth
              label="Moeda"
              defaultValue="real"
              onChange={(e) => handleChange("currency", e.target.value)}
            >
              <MenuItem value="real">Reais</MenuItem>
              <MenuItem value="dollar">Dólar</MenuItem>
              <MenuItem value="euro">Euro</MenuItem>
              <MenuItem value="pesos">Pesos</MenuItem>
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel style={{ color: "#181818" }}>
              Forma de pagamento
            </InputLabel>
            <Select
              startAdornment={
                <InputAdornment position="start">
                  <CreditCard />
                </InputAdornment>
              }
              value={data.paymentMethod}
              defaultValue="cash"
              onChange={(e) => handleChange("paymentMethod", e.target.value)}
              fullWidth
              label="Forma de pagamento"
            >
              <MenuItem value="cash">Dinheiro</MenuItem>
              <MenuItem value="pix">Pix</MenuItem>
              <MenuItem value="card">Cartão</MenuItem>
            </Select>
          </FormControl>
          <Typography variant="body1" component="h3">
            Outros
          </Typography>
          <FormControl>
            <InputLabel style={{ color: "#181818" }}>Observações</InputLabel>
            <OutlinedInput
              value={data.observation}
              fullWidth
              onChange={(e) => handleChange("observation", e.target.value)}
              label="Observações"
              placeholder="Ex. pago metade, levar cadeirinha;"
              multiline
              rows={3}
            />
          </FormControl>
          <Divider />
          <Button type="submit" variant="contained" color="primary">
            {key ? "Atualizar" : "Adicionar"}
          </Button>
        </Stack>
      </FormControl>
    </form>
  );
}
